<template>
  <vx-card style="padding-left: 10px">
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="email">Venue</label>
          <p id="email">{{recommendationDetails.location.name}}</p>
          <p id="email">{{recommendationDetails.category.name}}</p>
          <p id="email">{{recommendationDetails.location.venueLocation.address}}</p>
          <p id="email">{{recommendationDetails.location.venueLocation.state}}</p>
          <p id="email">{{recommendationDetails.location.venueLocation.cc}}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="status">Recommended By</label>
          <p id="status">{{recommendationDetails.user.firstName}} {{recommendationDetails.user.lastName}}({{recommendationDetails.user.username}})</p>
          <p id="status">{{recommendationDetails.user.email}}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="comment">Comment</label>
          <p id="comment">{{recommendationDetails.comment}}</p>
        </div>
      </vs-col>

      <!-- <vs-col vs-type="flex" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="profileImage">Profile Image</label>
          <a
            style="display:block;"
            id="profileImage"
            v-if="userDetail.profileImage"
            :href="userDetail.profileImage"
            target="_blank"
          >
            <vs-avatar size="100px" :src="userDetail.profileImage" />
          </a>
          <p v-else id="profileImage">NA</p>
        </div>
      </vs-col>-->

    </vs-row>
    <vs-row vs-justify="flex-end">
      <vs-button v-if="recommendationDetails.archived===true"
        color="success"
        style="margin-left:5px;"
        @click="activateHandler()"
      >Activate</vs-button>
      <vs-button v-if="recommendationDetails.archived===false"
        color="danger"
        style="margin-left:5px;"
        @click="archiveHandler()"
      >Archive</vs-button>
      <vs-button
        color="danger"
        style="margin-left:5px;"
        @click="$router.replace('/admin/recommendations')"
      >Back</vs-button>
    </vs-row>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import {recommendationMixin} from "../../mixins/recommendationMixin"

export default {
  name: "RecommendationDetails",
  mixins: [ recommendationMixin ],
  data: () => ({
    userDetail: {},
    recommendationDetails:{},
    recommendationId:null
  }),
  methods: {
    getDetails(id) {
      let self = this;
      this.getRecommendationDetails(id).then((res) => {
        this.recommendationDetails = res.recommendationDetails;
      });
    },
    archiveHandler(){
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm Archive`,
        text: `Are you sure you want to archive this recommendation.`,
        accept: this.confirmArchive,
        acceptText: "Archive",
      });
    },
    confirmArchive(){
      this.archiveRecommendations({_id:this.recommendationId,archiveStatus:'archive'}).then((res) => {
        this.$vs.notify({
          title: "Recommendation archived.",
          text: res.message.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.recommendationDetails.archived=true;
      });
    },
    activateHandler(){
      this.$vs.dialog({
        type: "confirm",
        color: "success",
        title: `Confirm Activate`,
        text: `Are you sure you want to activate this recommendation.`,
        accept: this.confirmActivate,
        acceptText: "Activate",
      });
    },
    confirmActivate(){
      this.archiveRecommendations({_id:this.recommendationId,archiveStatus:'activate'}).then((res) => {
        this.$vs.notify({
          title: "Recommendation activated.",
          text: res.message.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.recommendationDetails.archived=false;
      });
    }

  },
  created() {
    this.recommendationId = this.$route.params.id;
    this.getDetails(this.recommendationId);
  },
};
</script>
<style>
.ql-editor {
  min-height: 200px;
}
</style>
