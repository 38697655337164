import { gql } from "apollo-boost"
export const recommendationMixin = {
    methods:{
        getRecommendations(options){
            const getRecommendationsForAdmin = gql`
            query getRecommendationsForAdmin($options: RecommendationPaginationInput!){
                getRecommendationsForAdmin(options: $options){
                    recommendations{
                        _id
                        comment
                        createdAt
                        user{
                            _id
                            username
                        }
                        location{
                            _id
                            name
                        }
                    }
                    pagination{
                        total
                        page
                    }
                }
            }
            `;
            return this.$apollo.query({
                query: getRecommendationsForAdmin,
                variables:{
                    options
                }
            }).then(response => {
                return response.data.getRecommendationsForAdmin
            })
        },
        getRecommendationDetails(recommendationId){
            const getRecommendationDetailsForAdmin = gql`
            query getRecommendationDetailsForAdmin($recommendationId: ID!){
                getRecommendationDetailsForAdmin(recommendationId: $recommendationId){
                    recommendationDetails{
                        _id
                        comment
                        createdAt
                        user{
                            _id
                            firstName
                            lastName
                            username
                            email
                        }
                        location{
                            _id
                            name
                            venueLocation{
                                address
                                location{
                                    coordinates
                                }
                                cc
                                state
                                country
                            }
                        }
                        category{
                            _id
                            name
                        }
                        archived
                    }

                }
            }
            `;
            return this.$apollo.query({
                query: getRecommendationDetailsForAdmin,
                variables:{
                    recommendationId
                }
            }).then(response => {
                return response.data.getRecommendationDetailsForAdmin
            })
        },
        getLocations(options){
            const getLocationsForFilterAdmin = gql`
            query getLocationsForFilterAdmin($options: FilterPaginationInput!){
                getLocationsForFilterAdmin(options: $options){
                    _id
                    name
                }
            }
            `;
            return this.$apollo.query({
                query: getLocationsForFilterAdmin,
                variables:{
                    options
                },
                fetchPolicy: 'network-only'
            }).then(response => {
                return response.data.getLocationsForFilterAdmin
            }).catch( err => {
                console.log(err)
            })
        },
        getUsers(options){
            const getUsersForFilterAdmin = gql`
            query getUsersForFilterAdmin($options: FilterPaginationInput!){
                getUsersForFilterAdmin(options: $options){
                    _id
                    firstName
                    lastName
                    username
                    email
                }
            }
            `;
            return this.$apollo.query({
                query: getUsersForFilterAdmin,
                variables:{
                    options
                },
                fetchPolicy: 'network-only'
            }).then(response => {
                return response.data.getUsersForFilterAdmin
            }).catch( err => {
                console.log(err)
            })
        },
        updatePage(pageData){
            const updatePage = gql`
            mutation savePageForAdmin($pageData: PageInput!){
                savePageForAdmin(request: $pageData){
                    message{
                        message
                        code
                    }
                }
            }
            `;
            return this.$apollo.mutate({
                mutation: updatePage,
                variables:{
                    pageData
                }
            }).then(response => {
                return response.data.savePageForAdmin
            })
        },
        archiveRecommendations(options){
          let archiveRecommendation = gql`
            mutation archiveRecommendation($_id: ID!,$archiveStatus:String!){
                archiveRecommendation(_id:$_id, archiveStatus:$archiveStatus){
                    message{
                        message
                        code
                    }
                }
            }
            `;
          return this.$apollo.mutate({
            mutation: archiveRecommendation,
            variables:options

          }).then(response => {
            return response.data.archiveRecommendation
          })
        }
    }
}
